import React from 'react'

export default function About() {
    return (
        <div className="centerBlockOuter">
        <div className="centerBlockInner">
        <h3>Motivation:</h3>

<p>Have you ever wonder how much data a website like google, facebook or even randomperson.com can 
    collect on visitors? With little effort, a website can collect personal identifiable data. 
    This website was just written over a few days , using easily available API. A single page collecting 
    this info may not be neferious, but if websites aggregated this data either via this data being sold/bought 
    to/from data broker or via buisness partnership, this data can be used to target users at best for ads 
    and at worst for government or private data brokers. </p>
        </div>
      </div>
    )
}


import './App.css';
import { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'; 
import Main from './components/Main';
import About from './components/About';
import Navigation from './components/Navigation';
import Map from './components/Map';
import {createOrUpdate, fetchCities, getFingerPrint} from './services/api'
function App() {
  const[visitor, setVisitor] = new useState(null)
  const[cities, setCities] = new useState([])

  useEffect(() => {
    const getVistorId = async () => {
      const fingerprint = await getFingerPrint();
      const vistorFromServer = await createOrUpdate(fingerprint.visitorId);
      setVisitor(vistorFromServer);
    };

    getVistorId();
  }, []);
  

  useEffect( () => {
    const getCities = async() => {
      const data = await fetchCities()
      console.log("usereffect getcities in fun")
      console.log(data)
      setCities(data)
    };

    getCities();
    console.log("usereffect getcities")
    console.log(cities)

  }, []);


  return (
    <div className="App">
      <Navigation/>
    
      <main>
    <Switch>
      <Route exact path='/' render={(props)=><Main {...props} visitor={visitor}/>} />
      <Route exact path='/about' component={About}/>
      <Route exact path='/map' render={(props)=><Map {...props} cities={cities}/>}/>
    </Switch>
  </main>
    </div>
  );
}

export default App;

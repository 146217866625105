import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fpPromise = FingerprintJS.load();

const getVisitorIds = async (fingerprint) => {
  const res = await fetch(process.env.REACT_APP_BACKEND_SERVER + `/visitorid/${fingerprint}`);
  const data = await res.json();
  return data;
};

const getFingerPrint = async () => {
  const fp = await fpPromise;
  const result = await fp.get();
  return result;
};

const getIpaddress = async () => {
  const res = await fetch(process.env.REACT_APP_BACKEND_SERVER+ "/ip");
  const data = await res.text();
  return data;
};

const createOrUpdate = async (fingerprint) => {
  const model = { id: fingerprint };
  const res = await fetch(process.env.REACT_APP_BACKEND_SERVER + "/visitorid", {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(model),
  });

  const data = await res.json();

  return data;
};


const fetchCities = async() => {
  const res = await fetch(process.env.REACT_APP_BACKEND_SERVER+"/visitorcity")
  const data = await res.json()
  return data;
}

const getMap = (coordinate) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${coordinate.lat},${coordinate.lon}&markers=color:red%7Clabel:%7C${coordinate.lat},${coordinate.lon}&size=500x300&zoom=12&key=${process.env.REACT_APP_MAP_API}`;

export { getFingerPrint, getVisitorIds, createOrUpdate, getIpaddress, getMap, fetchCities };





const getVisitInfo = ({data}) => {

  const entry = data.entries[data.entries.length-1]
  const model = [
    {
      key: 'Fingerprint',
      title: 'Fingerprint',
      value: data.id,
    },
    {
      key: 'Ipaddress',
      title: 'Ipaddress',
      value: entry.ip,
    },
    {
      key: 'Isp',
      title: 'Isp',
      value: entry.isp,
    },
    {
      key: 'Country',
      title: 'Country',
      value: entry.country,
    },
    {
      key: 'City',
      title: 'City',
      value: entry.city,
    },


  ]

  return model
}


const getComputerInfo = ({data}) => {
  const model = [
    {
      key: 'Platform',
      title: 'Platform',
      value: data.platform.value,
    },
    {
      key: 'PreferLanguage',
      title: 'Prefer Language',
      value: data.languages.value[0],
    },
    {
      key: 'Timezone',
      title: 'Timezone',
      value: data.timezone.value,
    },
    {
      key: 'CookiesEnabled',
      title: 'Cookies Enabled',
      value: data.cookiesEnabled.value ? 'true' : 'false',
    },
    {
      key: 'CPU',
      title: 'CPU',
      value: data.hardwareConcurrency.value,
    },
    {
      key: 'Resolution',
      title: 'Resolution',
      value: data.screenResolution.value[0] + 'x' + data.screenResolution.value[1],
    }

  ]

  return model
}



export{
    getComputerInfo,
    getVisitInfo
};

import React from "react";
import "../styles/custom.css"
const VisitLog = ({data}) => {
  return (
    <div className="centerBlockOuter">
      <div className="centerBlockInner">
      <h2>Visit History</h2>
        <div className="contentBlock">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">city</th>
                <th scope="col">isp</th>
                <th scope="col">date</th>
              </tr>
            </thead>

            {data.map((item) => (
              <tbody key={item.date}>
                <tr>
                  <td>{item.city}</td>
                  <td>{item.isp}</td>
                  <td>{item.date}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <p><b>Explanation</b>: This a history log of visits to this site using your current browser.
        This visit history is periodically deleted for your privacy.</p>
      </div>
    </div>
  );
};

export default VisitLog;
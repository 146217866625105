import React from 'react'
import { getComputerInfo } from '../services/datamodel';

import Table from './Table';
const ComputerInfo = (data) => {
    return (
        <div className="centerBlockOuter">
        <div className="centerBlockInner">
          <h2>Computer Info</h2>
          <Table data={getComputerInfo(data)}></Table>
          <p><b>Explanation</b>: This is a subset of software/hardware infomation collected 
                from your browser via javascript execution.</p>
        </div>
      </div>
    )
}

export default ComputerInfo

import React from 'react'
import { getMap } from '../services/api'
import { getVisitInfo } from '../services/datamodel'
import Table from './Table'

const VisitorInfo = (data) => {
    const coordinate = {
      lat: data.data.entries[data.data.entries.length-1].lat,
      lon: data.data.entries[data.data.entries.length-1].lon
    }
    return (
      <div className="centerBlockOuter">
        <div className="centerBlockInner">
          <h2>Visitor Info</h2>

          <Table data={getVisitInfo(data)}></Table>
          <img
            src={getMap(coordinate)}
            className="img-fluid"
            alt="Software Development"
          />
        
                  <p><b>Explanation</b>: Fingerprint is a uniqueid genrated by hashing hardware, 
                  software settings and browser characteristic of your device. For most browser, using incognito will result
                  in the same fingerprint. This hash of your device may change based on changes on your hardware or
                  updates in your software. The IP info is infomation collected from your ISP. If you are not on VPN
                  the infomation will have the detail of your ISP local router infomation, otherwise it would be the remote router
                  that your vpn connection has tunnel to. </p>
      
        </div>
      </div>
    );
}

VisitorInfo.propTypes = {

}

export default VisitorInfo

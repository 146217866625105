import React from 'react'
import { useState, useEffect } from 'react'
import VisitorInfo from './VisitorInfo'

import ComputerInfo from './ComputerInfo';
import { getFingerPrint} from '../services/api'
import VisitLog from './VisitLog';

export default function Main({visitor}) {
    const[computerinfo, setComputerinfo] = new useState(null)
    useEffect( () => {
      const getfs = async() => {
        const fingerprint = await getFingerPrint()
        setComputerinfo(fingerprint.components)
      }
      getfs()
    }, [])

    return (
        <div>
            {visitor ? (<VisitorInfo data={visitor}></VisitorInfo>) : (<h2>Waking up service...</h2>) }
            {computerinfo ? (<ComputerInfo data={computerinfo}/>) : (<h2>loading</h2>) }
            {visitor ? (<VisitLog data={visitor.entries}/>) : (<h2>Waking up service...</h2>) }
        </div>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
export default function Navigation() {
    return (
<nav className="navbar navbar-expand-sm navbar-dark bg-primary flex-sm-nowrap flex-wrap">
    <div className="container-fluid">
        <button className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar5">
            <span className="navbar-toggler-icon"></span>
        </button>
        <span className="navbar-brand flex-grow-1"></span>
        <div className="navbar-collapse collapse flex-grow-1 justify-content-center" id="navbar5">
            <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/about">About</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/">Visitor Fingerprint</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/map">Map</Link>
                </li>
            </ul>
        </div>
        <div className="flex-grow-1">
            
        </div>
    </div>
</nav>
    )
}
